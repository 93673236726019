<template>
  <div class="DomainNamesView">
    <SearchBlockHeader v-if="isAuthenticated" :extendedTemplate="true" />
    <SearchBlockHeader class="larger-header" v-if="!isAuthenticated" :extendedTemplate="true" />

    <div v-show="isAuthenticated" class="o-container md-gutter container-layout">
      <div v-if="checkAvailabilityLoader">
        <Loader />
      </div>

      <div v-else-if="showSearchedDomains" class="searched-domains-container">
        <md-button v-show="isAuthenticated" class="md-raised back-btn" @click="goBackToDomains()">
          <md-icon>arrow_back_ios_new</md-icon>
          <span>{{ $t('common.actions.goBack') }}</span>
        </md-button>
        <SearchedDomains />
      </div>

      <div v-else-if="!showSearchedDomains && isAuthenticated" class="card-domains-layout md-gutter">
        <UserDomainsCard class="flex-domains" :title="this.reserveCardContent.title"
                                              :subtitle="this.reserveCardContent.subtitle"
                                              :image="getImgUrl(this.reserveCardImage.image)"
                                              :alt="this.reserveCardContent.imageAlt"
                                              :to="this.reserveCardContent.to">
          <UserDomainsReserve />
        </UserDomainsCard>

        <UserDomainsCard class="flex-domains" :title="this.renewCardContent.title"
                                              :subtitle="this.renewCardContent.subtitle"
                                              :image="getImgUrl(this.renewCardImage.image)"
                                              :alt="this.renewCardImage.imageAlt"
                                              :to="this.renewCardContent.to">
          <UserDomainsRenew />
        </UserDomainsCard>
      </div> <!-- /.md-layout -->
    </div> <!-- /.o-container-gray -->
  </div> <!-- /.DomainNamesView -->
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import {
  CART_RESET_STATE
} from '@/store/actions.type';
import {
  SET_AVAILABILITY,
  SET_SHOW_SEARCHED_DOMAINS,
} from '@/store/mutations.type';
import Loader from '@/components/util/Loader';
import SearchBlockHeader from '@/components/SearchBlockHeader';
import SearchedDomains from '@/components/SearchedDomains';
import UserDomainsCard from '@/components/util/UserDomainsCard';
import UserDomainsReserve from '@/components/UserDomainsReserve';
import UserDomainsRenew from '@/components/UserDomainsRenew';

export default {
  name: 'DomainNamesView',
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(CART_RESET_STATE);
    return next();
  },
  components: {
    Loader,
    SearchBlockHeader,
    SearchedDomains,
    UserDomainsCard,
    UserDomainsReserve,
    UserDomainsRenew
  },
  data() {
    return {
      reserveCardImage: {
        image: 'register-domain',
        imageAlt: 'Register domain icon - Computer with a pen',
      },
      reserveCardContent: {
        title: this.$t('domainNamesView.reserveTitle'),
        subtitle: this.$t('domainNamesView.reserveSubtitle'),
        to: '/user-domains-reserve'
      },
      renewCardImage: {
        image: 'renew-domain',
        imageAlt: 'Renew domain icon - Computer with a reload arrow',
      },
      renewCardContent: {
        title: this.$t('domainNamesView.renewTitle'),
        subtitle: this.$t('domainNamesView.renewSubtitle'),
        to: '/user-domains-renew'
      }
    }
  },
  computed: {
    ...mapGetters([
      'showSearchedDomains',
      'checkAvailabilityLoader',
      'isAuthenticated'
    ])
  },
  methods: {
    goBackToDomains() {
      this.$store.commit(SET_SHOW_SEARCHED_DOMAINS, false);
      this.$store.commit(SET_AVAILABILITY, []);
      this.$router.push({name: 'domain-names'});
    },
    getImgUrl(imageName) {
      try {
        return require(`@/assets/images/${imageName}.png`)
      } catch(error) {
        return require(`@/assets/images/image-placeholder.png`)
      }
    }
  }
};
</script>

<style lang="scss">
.DomainNamesView {

  .back-btn .md-button-content {
    display: flex;
  }
}
</style>

<style lang="scss" scoped>
.DomainNamesView {
  overflow: hidden;

  .container-layout {
    padding-left: 0;
    padding-right: 0;
    @include desktop {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .searched-domains-container {
    max-width: 120rem;
    margin: 1.5rem auto;
  }

  .card-domains-layout {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: stretch;
    margin: 3rem auto;
    min-height: 50rem;
  }

  .flex-domains {
    flex: 1;
  }

  .back-btn {
    @include flex-center-xy;
    margin: 0 0 1.6rem;

    i {
      font-size: 1.6rem !important;
      transform: translate(-.4rem, 0);
    }

    i, span {
      line-height: 4rem;
    }
  }

  .larger-header {
    height: 42rem;
    @include desktop {
      height: 50rem
    }
  };
}
</style>
