<template>
  <div class="SearchedDomains">
    <h3 v-if="domainsSearched" class="o-default-h3">
      {{ $t('searchedDomains.youSearchedFor') }} <span class="u-strong">{{ domainsSearched }}</span>
    </h3>

    <div v-for="domain in this.domains" :key="domain.domainName">

      <md-card v-if="domain.available" class="card-domain-available">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-70 md-small-size-50 md-xsmall-size-100">
              <h3 class="o-default-h3 searched-domain-ls">
                {{ domain.domainName }} {{ $t('searchedDomains.isAvailable') }}
              </h3>
              <!-- <div class="info-text-container">
                <i class="material-icons md-24 u-green">done</i>
                <span class="o-default-h4 info-text">You can make an offer to the current owner of this domain</span>
                <br />
                <i class="material-icons md-24 u-green">done</i>
                <span class="o-default-h4 info-text">We can help you negotiate with the current owner</span>
                <br />
                <i class="material-icons md-24 u-green">done</i>
                <span class="o-default-h4 info-text">You can find similar domain names below</span>
              </div>  /.info-text-container -->
            </div> <!-- /.md-layout-item -->
            <div class="md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 searched-domain-rs">
              <div class="searched-domain-price md-title">{{ domain.domainPriceEuro }}{{
                $t('cartItems.euroSign') }}/{{ $t('searchedDomains.year') }}</div>
              <!-- If the domain is not in the cart -->
              <md-button class="position-bottom-right-available md-raised md-primary" v-if="domain.available == 1"
                @click="domain.available = addToCart(domain.domainName, domain.available); showCopyConfirmation()">
                {{ $t('common.actions.addToCart') }}
              </md-button>
              <!-- If the domain has been added to the cart -->
              <md-button class="position-bottom-right-available md-raised md-primary"
                v-if="domain.available === 'inCart'" @click="Helpers.goToRoute('cart', $router)">
                {{ $t('searchedDomains.viewBtn') }}
              </md-button>
            </div> <!-- /.md-layout-item -->
          </div> <!-- /.md-layout -->
        </md-card-content>
      </md-card> <!-- /.card-domain-available -->

      <md-card v-else class="card-domain-unavailable">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-70 md-small-size-50 md-xsmall-size-100">
              <h3 class="o-default-h3 searched-domain-ls">{{ domain.domainName }}
                {{ $t('searchedDomains.isUnavailable') }}</h3>
              <!-- <div class="info-text-container">
                <i class="material-icons md-24 u-green">done</i>
                <span class="o-default-h4 info-text">You can make an offer to the current owner of this domain</span>
                <br />
                <i class="material-icons md-24 u-green">done</i>
                <span class="o-default-h4 info-text">We can help you negotiate with the current owner</span>
                <br />
                <i class="material-icons md-24 u-green">done</i>
                <span class="o-default-h4 info-text">You can find similar domain names below</span>
              </div> /.info-text-container -->
            </div> <!-- /.md-layout-item -->
            <div class="md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100">
              <div class="position-bottom-right-unavailable">
                <md-button class="md-raised md-primary" @click="Helpers.goToRoute('help/faq', $router)">
                  {{ $t('searchedDomains.learnMoreBtn') }}
                </md-button>
                <md-button class="md-raised" @click="Helpers.goToRoute('contact', $router)">
                  {{ $t('searchedDomains.makeOfferBtn') }}
                </md-button>
              </div>
            </div> <!-- /.md-layout-item -->
          </div> <!-- /.md-layout -->
        </md-card-content>
      </md-card> <!-- /.card-domain-unavailable -->
    </div> <!-- /.card-domain -->

    <!-- TO BE IMPLMENTED -->
    <!-- <section class="searched-domains-similar">
      <h3 class="o-default-h3">Similar domain names</h3>
      <SimilarDomains />
    </section> /.searched-domains-similar -->
    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration"
      :md-active.sync="snackbar.showSnackbar" md-persistent>
      <span>{{ $t('searchedDomains.confirmation') }}</span>
      <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </div> <!-- /.SearchedDomains -->
</template>

<script>
import { mapGetters } from 'vuex';
import {
  FETCH_CART,
  ADD_TO_CART,
  FETCH_USER_DETAILS_ALL
} from '@/store/actions.type';
// import SimilarDomains from '@/components/SimilarDomains';

export default {
  name: 'SearchedDomains',
  components: {
    // SimilarDomains
  },
  data() {
    return {
      snackbar: {
        showSnackbar: false,
        duration: 4000,
        position: 'center',
      },
      domainsSearched: '',
    };
  },
  updated() {
    this.checkOccurences();
  },
  mounted() {
    this.$store.dispatch(FETCH_USER_DETAILS_ALL);
    this.$store.dispatch(FETCH_CART);
    this.domainsSearched = '';
    for (let i = 0; i < this.domains.length; i++) {
      this.domainsSearched += this.domains[i].domainName + ' ';
    }
  },
  computed: {
    ...mapGetters(['domains', 'userDetailsAddresses', 'entries']),
  },
  watch: {
    domains() {
      this.domainsSearched = '';
      for (let i = 0; i < this.domains.length; i++) {
        this.domainsSearched += this.domains[i].domainName + ' ';
      }
    },
    entries() {
      for (let j = 0; j < this.domains.length; j++) {
        this.domains[j].available = true;
      }
      this.checkOccurences();
    }
  },
  methods: {
    checkOccurences() {
      // Check if there's at least one domain entry in the cart
      if (this.entries) {
        for (let i = 0; i < this.entries.length; i++) {
          for (let j = 0; j < this.domains.length; j++) {
            if (this.entries[i].productName == this.domains[j].domainName) {
              this.domains[j].available = 'inCart';
            }
          }
        }
      }
    },
    showCopyConfirmation() {
      this.snackbar.showSnackbar = true;
    },
    addToCart(name, availability) {
      const separators = ['.'];
      let auxName = name.split(separators.join());

      let params = [{
        domainName: name,
        tld: auxName[1],
        period: 1,
        domainType: 'REGISTER',
        domainDetails: this.userDetailsAddresses[0]
      }];

      this.$store.dispatch(ADD_TO_CART, params);
      availability = 'inCart';

      return availability;
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchedDomains {

  .md-button {
    float: right;
  }

  .md-card {
    margin: 1.2rem 0;
    padding: .8rem;
    text-align: left;
  }

  .md-card-content {
    padding: .8rem 1.6rem;
  }

  .md-card-header {
    min-height: auto;
    padding: .8rem 1.6rem;
  }

  .md-layout-item {
    position: relative;
  }

  .card-domain-available {
    border-left: .8rem solid $palette-success;
  }

  .card-domain-unavailable {
    border-left: .8rem solid $palette-error;
  }

  .info-text {
    display: inline-block;
    margin-left: .8rem;
    transform: translate(0, -.4rem);
  }

  .position-bottom-right-available {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 0;
    right: 0;
    bottom: 0;

    @include desktop {
      position: relative;
    }
  }

  .position-bottom-right-unavailable {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 0;
    right: 0;
    bottom: 0;

    @include desktop {
      position: relative;
    }
  }

  .searched-domain-price {
    color: $black;
  }

  .o-default-h3 {
    @include truncate(35rem);
    text-wrap: wrap;

    @include tablet {
      @include truncate(70rem);
    }

    @include desktop {
      @include truncate(90rem);
    }
  }

  .searched-domain-ls {
    color: $palette-secondary-color-four;
    font-size: 2.4rem;
    font-weight: bold;
    padding-bottom: 2.4rem;
    text-wrap: wrap;
    @include truncate(35rem);

    @include tablet {
      @include truncate(70rem);
    }

    @include desktop {
      font-size: 3.2rem;
      @include truncate(90rem);
    }
  }

  .searched-domain-rs {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>
