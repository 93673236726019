<template>
  <div class="UserDomainsCard">
    <md-card class="user-domains-card">
      <div class="card-domains-header">
        <div class="card-domains-header--top">
          <img :src="image" class="register-icon" />
          <h2 class="u-font-body-thin card-title">{{ title }}</h2>
        </div>
        <p class="u-font-body-regular">{{ subtitle }}</p>
      </div>
      <md-card-content class="user-domains-card-content">
        <slot></slot>
      </md-card-content>
      <md-card-content class="u-cursor-pointer u-text-right">
        <router-link :to="to">{{ $t('domainNamesView.seeAll') }}</router-link>
      </md-card-content>
    </md-card>
  </div> <!-- /.UserDomainsCard -->
</template>

<script>
export default {
  name: 'UserDomainsCard',
  props: {
    title: String,
    subtitle: String,
    image: String,
    alt: String,
    to: String,
  },
};
</script>

<style lang="scss" scoped>
.UserDomainsCard {
  overflow: hidden;

  .user-domains-card {
    display: flex;
    flex-direction: column;
    min-height: 40rem;
    min-width: 100%;
  }

  .user-domains-card-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 0;
  }

  .card-domains-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    gap: 3rem;
    justify-content: center;
    min-height: 18rem;
    padding: 1rem 2rem;
    &--top {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .register-icon {
    min-height: 7rem;
    min-width: 7rem;
    height: 7rem;
    width: 7rem;
    @include desktop {
      min-height: 8rem;
      min-width: 8rem;
      height: 8rem;
      width: 8rem;
    }
  }

  .card-title {
    margin-left: 2rem;
    padding-bottom: 0;
  }
}
</style>
